import gql from 'graphql-tag';

export const CREATE_CUSTOMER_LEAD = gql`
  mutation createCustomerLead(
    $email: String!
    $sourceUrl: String!
    $cityId: Int
    $closestSofarCityId: Int
    $sendPromoCodeEmail: Boolean
  ) {
    createCustomerLead(
      email: $email
      sourceUrl: $sourceUrl
      cityId: $cityId
      closestSofarCityId: $closestSofarCityId
      sendPromoCodeEmail: $sendPromoCodeEmail
    ) {
      customerLead {
        email
      }
      user {
        homeCity {
          cachedSlug
        }
        email
      }
    }
  }
`;
